<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Submit feedback</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <textarea
              v-model.trim="form.message"
              id="message"
              class="form-control"
              rows="6"
              placeholder="Your message..."
            ></textarea>
            <label for="message">Message<sup class="text-danger">*</sup></label>
            <div class="form-text">Minimum 10 characters.</div>
          </div>

          <ErrorMessage v-if="error" :error="error"></ErrorMessage>

          <div v-if="isSuccess" class="alert alert-success">Sent!</div>
        </div>
        <div class="modal-footer">
          <SubmitButton
            :text="'Submit'"
            :textBusy="'Please wait...'"
            :isLoading="isLoading"
            :disabled="isLoading || isSubmitDisabled"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { httpPost } from '@/core/http';

const initialState = () => ({
  isLoading: false,
  isSuccess: false,
  error: null,
  form: {
    message: '',
  },
});

export default {
  name: 'FeedbackModal',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isSubmitDisabled() {
      return this.form.message.length < 10;
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.isSuccess = false;
      this.error = null;
      try {
        const payload = {
          ...this.form,
          url: this.$router.history.current.path,
        };
        await httpPost('/feedback', payload);
        this.reset();
        this.isSuccess = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    reset() {
      Object.assign(this.$data, initialState());
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return initialState();
  },
};
</script>
